<template>
    <div class="footer-bottom">
        <div class="main-auto">
            <div class="contact"><span>{{$t('footer.concatWe')}}</span></div>
            <div class="flex menu ac">
                  <img class="logo"  v-if="logo" :src="logo" alt="">
                <div  class="div-tab">{{$t('footer.homePage')}}</div>
                <div @click="goPage('/aboutUs/culture')" class="div-tab">{{$t('footer.aboutWe')}}</div>
                <div   @click="goPage('/aboutUs/messageBoard')"  class="div-tab ">{{$t('footer.feedback')}}</div>
            </div>
            <div v-if="config.system_info" class="bottom-info flex ac ">
                <!-- <img class="b-logo" src="../assets/bottom-logo.png" alt> -->
                <!-- <img v-if="info.guide_map_info.image.value.length>0" class="b-logo" :src="info.guide_map_info.image.value[0]" alt> -->
                <div class="service-info flex ac jb">
                    <div class="flex">
                        <div class="m-r-60">
                                    <div v-if="config && config.system_info && config.system_info.complaint_tel && config.system_info.complaint_tel.value" class="flex dc">
                                        <div class="label">{{$t('footer.complaintsHotline')}}：</div>
                                        <div class="text">{{ config.system_info.complaint_tel.value }}</div>
                                    </div>
                                    <div  v-if="config && config.system_info && config.system_info.urgent_tel && config.system_info.urgent_tel.value" class="flex dc">
                                        <div class="label">{{$t('footer.emergencyHotline')}}：</div>
                                        <div class="text">{{ config.system_info.urgent_tel.value }}</div>
                                    </div>
                                
                            </div>
                            <div class="m-r-60">
                                    <div v-if="config && config.system_info && config.system_info.service_url && config.system_info.service_url.value" class="flex dc">
                                        <div class="label">{{$t('footer.adress')}}：</div>
                                        <div class="text">{{ config.system_info.service_url.value }}</div>
                                    </div>
                                    <div v-if="config && config.system_info && config.system_info.postal_code && config.system_info.postal_code.value" class="flex dc">
                                        <div class="label">{{$t('footer.postalCode')}}：</div>
                                        <div class="text">{{ config.system_info.postal_code.value }}</div>
                                    </div>
                            </div>
                            <div>
                                <div v-if="config && config.system_info && config.system_info.service_tel && config.system_info.service_tel.value" class="flex dc">
                                    <div class="label">{{$t('footer.tel')}}：</div>
                                    <div class="text">{{ config.system_info.service_tel.value }}</div>
                                </div>
                                <div v-if="config && config.system_info && config.system_info.service_time && config.system_info.service_time.value" class="flex dc">
                                    <div class="label">{{$t('footer.time')}}：</div>
                                    <div class="text">{{ config.system_info.service_time.value }}</div>
                                </div>
                            </div>
                    </div>
                    <div class="qrcode flex ac">
                        <div  class="item">
                            <img  v-if="config.system_info && config.system_info.qr_code_url1.value[0]" :src="config.system_info.qr_code_url1.value[0]" alt>
                            <div v-if="config.system_info && config.system_info.qr_code_name1" class="only">{{config.system_info.qr_code_name1.value}}</div>
                        </div>
                        <div class="item">
                            <img v-if="config.system_info && config.system_info.qr_code_url2.value[0]" :src="config.system_info.qr_code_url2.value[0]" alt>
                            <div v-if="config.system_info && config.system_info.qr_code_name2" class="only">{{config.system_info.qr_code_name2.value}}</div>
                        </div>
                      </div>
                     
                </div>
            </div>
            
        </div>
        <div @click="open" v-if="config.system_info && config.system_info.icp_permit && config.system_info.icp_permit.description" class="copy-right">{{config.system_info.icp_permit.value }}</div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
var util = require("@/utils/util");
export default {
    name: "Bottom",
    data() {
        return {
        }
    },
    mounted() {
        
    },
    computed: {
      ...mapGetters(['config','logo']),
    },
    methods: {
        open(){
              window.open(this.config.system_info.icp_url.value,'_blank');
        },
        goPage(page){
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach( (item,index) => {
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (   childrenItem.page == page ) {
                                e = childrenItem;
                                firstMenu = item.name;
                                secondMenu = childrenItem.name;
                            }
                    })  
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url : e.page,data : { id : e.id,parent_id :e.parent_id }})
        },
    },
}
</script>
<style scoped lang="scss">
    .div-tab {
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
    }
    .div-tab:hover {
        color: var(--bg-color) !important;
    }
    .logo {
        height: 90px;
        margin-right: 30px;
    }
    .m-r-60 {
        margin-right: 60px;
    }
    .qrcode {
        img {
            width: 100px;
            height: 100px;
        }
        .item {
            margin-right: 30px;
            width: 100px;
            div {
                text-align: center;
            }
        }
        margin-bottom: 50px;
    }
    .copy-right {
        cursor: pointer;
    }
    .label {
        color: #999;
        font-size: 16px;
    }
    .text {
        color: #333;
        font-size: 20px;
        font-weight: bold;
        max-width: 500px;
    }
</style>