<template>
    <div class="container-home survey-home senic-home">
        <HeaderTab @getPoint="changeTab"></HeaderTab>
        <img class="survey-img" :src="banner" alt="">
        <section class="survey-bottom flex ac jb">
            <div class="flex ac jc">
                <section v-for="item in info" :class="['flex ac jc survey-tab', choice == item.remark ? 'active' : '']" @click="changeTab(item.remark)">{{item.name}}</section>
            </div>
            <div class="icon-svy"></div>
        </section>
        <div>
            <div v-if="choice == 'notice'">
                <div class="content">
                    <img class="content-bg" src="../assets/suvery.png" />
                    <div class="senics-home">
                        <div class="senic-bg">NOTICE
                            <div class="senic-title">实时公告</div>
                        </div>
                        <section v-for="(item,index) in notice" @click="showIndex(index)">
                            <section class="flex ac jb h80" >
                                <div class="senic-name">{{item.title}}</div>
                                <div v-if="!item.show" class="senic-icon"></div>
                                <div v-else class="senic-time">{{item.create_time}}</div>
                            </section>
                            <section v-show="item.show" class="show-msgs" v-html="item.content"></section>
                        </section>
                    </div>
                </div>
            </div>
            <div v-if="choice == 'activity'">
                <div class="content">
                    <img class="content-bg" src="../assets/suvery.png" />
                    <div class="senics-home activity">
                        <section v-for="(item,index) in activity" @click="showIndex(index)">
                            <section class="flex ac jc h250" @click="goDetail(item)">
                                <img :src="item.image" alt="">
                                <section class="sctivity-right">
                                    <div class="sctivity-title only">{{item.title}}</div>
                                    <div class="sctivity-dec double" v-html="item.content"></div>
                                    <div class="timer">活动时间 {{item.activity_time}}</div>
                                    <div class="timer">活动地点 {{item.address}}</div>
                                </section>
                            </section>
                        </section>
                    </div>
                </div>
            </div>
            <div v-if="choice == 'mediareports'">
                <div class="content">
                    <img class="content-bg" src="../assets/suvery.png" />
                    <div class="senics-home">
                        <div class="senic-bg">MEDIAREPORTS
                            <div class="senic-title">新闻动态</div>
                        </div>
                        <section v-for="(item,index) in news" @click="showIndex(index,1)">
                            <section class="flex ac jb h80" >
                                <div class="senic-name">{{item.title}}</div>
                                <div v-if="!item.show" class="senic-icon"></div>
                                <div v-else class="senic-time">{{item.create_time}}</div>
                            </section>
                            <section v-show="item.show" class="show-msgs" v-html="item.content"></section>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            banner:'',
            info:[],
            notice:[],
            activity:[],
            news:[],
            choice:'notice',
            show:11
        }
    },
    watch: {

    },
    mounted() {
        if(this.$route.query.type) {
            this.choice = this.$route.query.type
        }
        this.post()
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open(this.$t('tip.loading'));
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[2].banner_list[0].image;
                        ts.info =  res.data[2].children;
                    }
            });
            util.requests("post", {
                url: "pc/dynamicData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    res.data.notice.forEach(item => {
                        item.show = false
                    });
                    res.data.news.forEach(item => {
                        item.show = false
                    });
                    ts.notice = res.data.notice;
                    ts.activity = res.data.activity;
                    ts.news = res.data.news;
                }
            });
        },
        changeTab(e) {
            this.choice = e
        },
        showIndex(e,f) {
            if (f) {
                this.news[e].show = !this.news[e].show;
            } else {
                this.notice[e].show = !this.notice[e].show;
            }
        },
        goDetail(e) {
            this.$router.push({path:'/detail',query: {type: 'activity', id:e.id}})
        }
    }
}
</script>