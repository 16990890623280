<!-- 面包屑 -->
<template>
    <div class="breadcrumb">
        <span>{{$t('aboutWe.currentLocation')}}：</span>
        {{nowLocation}}
    </div>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
export default {
    name: "my-breadcrumb",
    props: {
    },
    computed : {
        ...mapGetters(['nowLocation'])
    },
    data() {
        return {
        }
    },
}
</script>