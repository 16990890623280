<template>
   <div>
         <div class="container ">
            <div :class="['list-item flex']" v-for="(item,index) of list" :key="index">
                <div :style="{ backgroundImage : 'url(' + item.domain_image +')' }" class="images">

                </div>
                <div class="flex-1">
                       <div class="list-item-title only">
                            {{ item.name }}
                        </div>
                        <div class="list-item-open-time">
                            {{$t('home.openTime')}} :{{ item.open_time }}
                        </div>
                        <div class="list-item-desc three">
                            {{ item.synopsis }}
                        </div>
                        <div @click="queryDetail(item.content,item.name)" class="query-detail">
                                {{$t('home.viewDetails')}} >
                            </div>
                </div>
             
            </div>
         
    </div>
      <pagination-box :pagination="pagination" @pageChange="pageChange" ></pagination-box>
   </div>
  
</template>
<script>
import paginationBox from '@/components/common/pagination.vue'; 
import { Indicator } from "mint-ui";
var util = require("@/utils/util");
export default {
    data(){
        return {
            list : [
            ],
              pagination: {}, //分页数据
            page:1
        }
    },
    components: {
		paginationBox,
	},
    async  mounted() {
        Indicator.open(this.$t('tip.loading'));
        await this.commonGetList('os_scenic');
        Indicator.close()
    },
    methods : {
        queryDetail(detail,title){
            this.$emit('querydetail',detail,title)
        },
        commonGetList(table){
            const ts = this;
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : 5 , page : ts.page}
                }).then( (res) => {
                    if ( res.code ) {
                        ts.list = res.data.list
                         ts.pagination = {
                            page: res.data.current_page,
                            pagecount: res.data.last_page,
                            total: res.data.total,
                            pagesize: res.data.per_page
                        }
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
                   // 分页操作
         pageChange: async function(page) {
            this.page = page
              Indicator.open(this.$t('tip.loading'));
                await this.commonGetList('os_scenic');
                Indicator.close()
            },
    }
}
</script>
<style lang="scss" scoped>
    .container {
        margin-top: 31px;
        .list-item {
            margin-bottom: 24px;
            .images {
                width: 246px;
                height: 169px;
                margin-right: 19px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 4px;
            }
            .list-item-title {
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: bold;
                color: #000000;
                margin-top: 16px;
            }
            .list-item-open-time {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-top: 8px;
            }
            .list-item-desc {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
            .list-item-label {
                padding: 4px 8px;
                font-size: 10px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: var(--bg-color);
                border: 1px solid var(--bg-color);
                border-radius: 12px;
            }
            .query-detail {
                margin-top: 15px;
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: var(--bg-color);
                cursor: pointer;
            }
        }
    }
    .m-t-13 {
        margin-top: 13px;
    }
    .m-r-0 {
        margin-right: 0 !important;
    }
    .flex-1 {
        flex: 1;
    }
</style>