//数组操作函数
const ArrayFun={
	//判断数组是否含某值
  indexOfArr:function (arr,val) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == val) return i;
    }
    return -1;
  },
	//数组去重
	unique : function (arr){
		var res = [];
		var obj = {};
		for(var i=0; i<arr.length; i++){
		 if( !obj[arr[i]] ){
			obj[arr[i]] = 1;
			res.push(arr[i]);
		 }
		} 
		return res;
	 },
  //针对一维数组合并去重
  MergeArrayUnique:function (arr1, arr2) {
    var arr = arr1.concat(arr2);
  	arr = this.unique(arr);//再引用上面的任意一个去重方法
    return arr;
	},
	// 交集
	intersectionArray : function(arr1,arr2){
		return arr1.filter(function(v){ return indexOfArr(arr2,v) > -1 })
	},
	// 差集
	differenceArray : function(arr1,arr2){
		return arr1.filter(function(v){ return indexOfArr(arr2,v) === -1 })
	},
  
  //针对一维数组删除指定的值
  removeArrayVal: function (arr, val) {
    let indexOfArr = function(a,v) {
      for (var i = 0; i < a.length; i++) {
        if (a[i] == v) return i;
      }
      return -1;
    }
    var index = indexOfArr(arr,val);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  },
  //数据复制为纯数组
  copytoArray: function (arr) {
		if(typeof(arr)=='object'){
			let o={}
		//Object.assign(o,arr) 
			o=JSON.parse(JSON.stringify(arr))
			arr=[]
			for(let i in o){
				arr.push(o[i])
			} 
			return arr
				
		}else{
			return arr
		}
  }
}

export default ArrayFun