import myStorage from '../utils/modules/storage'
const TokenKey = '_token'
export function getToken() {
  return myStorage.local.get(TokenKey)
}
export function setToken(token) {
  return myStorage.local.set(TokenKey,token,60*60*24)
}
export function removeToken() {
  return myStorage.local.del(TokenKey)
}

const LanguageKey = 'Language'
export function getLanguage() {
  return myStorage.local.get(LanguageKey)
}
export function setLanguage(language) {
  return myStorage.local.set(LanguageKey,language)
}
export function removeLanguage() {
  return myStorage.local.del(LanguageKey)
}


const AidKey = '_Aid' //应用id设置
export function setAid(aid){
  return myStorage.local.set(AidKey,aid,60*60*24)
}
export function getAid() {
  return myStorage.local.get(AidKey)
}
export function removeAid() {
  return myStorage.local.del(AidKey)
}

const fileHost = 'fileHost' //图片和音频Host
export function setFileHost(val){
  val = JSON.stringify(val);
  return myStorage.local.set(fileHost,val,60*60*24)
}
export function getFileHost(type='') {
  let val = myStorage.local.get(fileHost);
  let hostList = JSON.parse(val);
  return type ? hostList[type] : hostList;
}
export function removeFileHost() {
  return myStorage.local.del(fileHost)
}

//动态菜单加载
import router from '@/router'
export const initMenu = {
  setMenuArrIdx:function(menuArr,parent_arr){
      for(var i in menuArr){
          var item=menuArr[i]
            var pid=item.parent_id
            if(pid&&parent_arr['_idx']){
              item['_idx']=parent_arr['_idx']+1
            }else{
              item['_idx']=1
            }
            if(pid&&parent_arr['level']){
              item['level']=parent_arr['level']+1
            }else{
              item['level']=1
            }
          if(item.children){
            this.setMenuArrIdx(item.children,item)
          }
      }
      return menuArr
  },
  setInit: function (rsMenuArr) {
    if (Object.keys(rsMenuArr).length == 0) {
      return
    }
    rsMenuArr = this.setMenuArrIdx(rsMenuArr,rsMenuArr)//标识菜单层级
    rsMenuArr = this.formatRoutes(rsMenuArr,false)
    //console.log('======',rsMenuArr)

    // 最后添加404页面
    // let unfound = { path: '*', redirect: '/404', hidden: true }
    // rsMenuArr.push(unfound)
    //addroutes添加新的动态router
    router.addRoutes(rsMenuArr)
  
    return rsMenuArr
  },
  //路由格式化
  formatRoutes: (aMenu,ischild=false) => {
    ///========数据初始化 start===========////////
    let aRouter = []
    let child_key ='children'//代表下级的key
    let pageurl_key ='page'//页面路劲的key
    let pagehide={
      hidekey  : 'status',//代表页面隐藏的key
      value: 0 //代表页面隐藏时的val
    }
    let btnurl_key='url'//代表页面中按钮点击请求url的key,该层级为当前页面层级的下级
    //设置meta选项
    let setMetafun=function(e){
      /*e= item=aMenu[key]
      *return {title: item.title,icon: item.icontag?item.icontag:'',id: item.id?item.id:'',pid:item.pid?item.pid:''}
      */
      //遍历当前页面层级的下级,获取该页面可点击按钮结果集
      function getPageShowBtns(childs){
        var rs=[]
        if(childs&&Object.keys(childs).length>0){
          for(var i in childs){
            var child=childs[i]
            //存储下级为隐藏的(代表用于内页点击打开的)，用于判断内页是否显示相应按钮
            if(child[pagehide.hidekey]==pagehide.value){
              rs.push(child[btnurl_key])
            }
          }
        }
        return rs
      } 
      let active_path = (e.status=='0'&&e.active_page) ? e.active_page : e.page;
      if(active_path.indexOf('/')!='0'){//是否是以/开头
        active_path='/'+active_path;
      }
      return {
        title        : e.name,
        active_path  : active_path,
        icon         : e.icon,
        id           : e.id,
        pid          : e.parent_id,
        pageShowBtns : getPageShowBtns(e[child_key]),//当前页面可显示的点击按钮
        _idx         : e._idx,
        level        : e.level
      }
    }
    ///========数据初始化 end===========////////
    for (let key in aMenu) {
      if (aMenu.hasOwnProperty(key)) { 
          var item=aMenu[key]
          if(ischild==true){//判断从下级进来
            if(item[btnurl_key]&&!item[pageurl_key]&&item[pagehide.hidekey]==pagehide.value){//只是单纯点击请求url用的（没有显示没有打开页面）
              continue;
            }
          }  
        // console.log(item)
          if(!item[pageurl_key]){
            item[pageurl_key]='';
          }
          let path=''
          if(item[pageurl_key].indexOf('http')!=-1 || item[pageurl_key].indexOf('https')!=-1){
            //判断是否是外链
            path=item[pageurl_key];
          }else{
            if(item[pageurl_key].indexOf('/')=='0'){//是否是以/开头
              path=item[pageurl_key];
            }else{
              path='/'+item[pageurl_key];
            }
            if(path=='/'){//针对当前页面item[pageurl_key]没有填写时读取子元素中补全
              //console.log(item['name'])
              var childs_arr=item[child_key]
              if(childs_arr&&Object.keys(childs_arr).length>0){
                var onechild_key=Object.keys(childs_arr)[0]
                var onechild=childs_arr[onechild_key]
                path=path+onechild[pageurl_key].split('/')[0]
              }
            }
          }

          // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! 开展4级页面后 内页不往下移 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          //层级2时且2有内页index，将该index页移到下级
          // if(item._idx>=2&&item[child_key]&&Object.keys(item[child_key]).length>0&&!item._isbulid){
          //   var path_arr=path.split('/')
          //   var path_arr_length=path_arr.length
          //   if(path_arr[path_arr_length-1]=='index'){
          //     path_arr[path_arr_length-1]=path_arr[path_arr_length-2]
          //     path=path_arr.join('/')//newpath
              
          //     var nweitem=Object.assign({},item)
          //     delete nweitem[child_key]
          //     nweitem._idx=nweitem._idx+1
          //     nweitem._isbulid=1
          //     var obj={}
          //     obj[nweitem.id]=nweitem
          //     item[child_key]=Object.assign({},item[child_key],obj)
          //   }
          // }

          let ishide=false//菜单是否显示
          if(item[pagehide.hidekey]==pagehide.value){
            ishide=true;
          }
          let oRouter ={
            path:path,
            hidden:ishide,
            menu_site: item.menu_site,
            //meta:{title: item.title,icon: item.icontag?item.icontag:'',id: item.id?item.id:'',pid:item.pid?item.pid:''},
            meta:setMetafun(item),
            //component(resolve){}
          // name: item.name ? item.name : '',
          } 
          //针对外链时不用component
          if(item[pageurl_key].indexOf('http') == -1 || item[pageurl_key].indexOf('https')!=-1){
            oRouter.component=(resolve)=> {
              item.component='views'+path
              let componentPath = item.component;
              require([`../${componentPath}.vue`], resolve)
              // if(ischild!=true){     
              //   item.component= 'Layout';//针对侧边栏一级菜单时固定调用Layout
              // }
              // if (item.component == 'Layout') {
              //   require(['../views/layout/Layout'], resolve)
              // }else{
              //   let componentPath = item.component;
              //   require([`../${componentPath}.vue`], resolve)
              // } 
            }
          }

          let childrenArr= []
          let child=item[child_key]
          if(child&&Object.keys(child).length>0){
              childrenArr=initMenu.formatRoutes(child,true)  
              oRouter.children= childrenArr
          }
          aRouter.push(oRouter)
      }
    }
    //console.log(aRouter)
    return aRouter
  }
}

