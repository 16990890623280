const header = {
    state : {
        menuList : [],
        logo : "",
        name : "",
        langArr : window.localStorage.getItem('langArr')? JSON.parse(window.localStorage.getItem('langArr')) : [],
        themeColor : {
            "--bg-color": '#64A346',
            "--bg-color-o": '#64A3461a',
        },
        tabList : [],
    nowLocation : "",
        secondMenu : "",
        config : "",//全局所有配置
        bennrtList : [],//轮播图
    },
    mutations : {
        SET_MENU_lIST:( state,list  ) => {
            state.menuList = list
        },
        SET_LOGO : ( state,value ) => {
            state.logo = value
        },
        SET_NAME : ( state,value ) => {
            state.name = value
        },
        SET_LANGARR : ( state,value ) => {
            state.langArr = value
            window.localStorage.setItem('langArr',JSON.stringify(value))
        },
        SET_THEME_COLOR : ( state,style ) => {
            console.log("style",style);
            state.themeColor = {
                "--bg-color": style.color.value,
                "--bg-color-o": style.hyaline_color.value,
            }
        },
        SET_TAB_LIST : ( state,value ) => {
            state.tabList = value
        },
        SET_NOW_LOCTION : ( state,value ) => {
            state.nowLocation = value
        },
        SET_SECOND_MENU : ( state,value ) => {
            state.secondMenu = value
        },
        SET_CONFIG : ( state,value ) => {
            state.config = value
        },
        SET_BENNRLIST : ( state,value ) => {
            state.bennrtList = value
        }   
    },
    actions : {
        setMenuList: ({commit,state }, list ) => {
            return commit('SET_MENU_lIST',list)
        },
        setLogo : ({commit,state},value) => {
            return commit('SET_LOGO',value)
        },
        setName:({commit,state },value ) => {
            return commit('SET_NAME',value)
        },
        setLangArr : ({commit,state},value) => {
            return commit('SET_LANGARR',value)
        },
        setThemeColor : ( { commit,state },style ) => {
            return commit('SET_THEME_COLOR',style)
        },
        setTabList : ( { commit,state },value ) => {
            return commit('SET_TAB_LIST',value)
        },
        setNowLocation: ( { commit,state } ,value ) => {
            return commit('SET_NOW_LOCTION',value)
        },
        setSecondMenu : ( { commit,state },value ) => {
            return commit('SET_SECOND_MENU',value)
        },
        setConfig : ( { commit,state },value ) => {
            return commit('SET_CONFIG',value)
        },
        setBennrtList : ( { commit,state },value ) => {
            return commit('SET_BENNRLIST',value)
        }
    }
}
export default header