<template>
    <div class="container-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <div  ref="mySwiper" v-if="bennrtList && bennrtList.length > 0" class="banner swiper-box">
            <div class="swiper-container banner-swiper">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" v-for="item in bennrtList" :key="item.id">
                        <div @click="getRoute(item)" :style="{ backgroundImage : 'url('+ item.domain_image + ')' }" class="images"></div>
                        <!-- <img  :src="item.domain_image" alt @click="banner(item.route_url,item.id)" /> -->
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div v-if="bennrtList.length > 1" class="zdy flex ac jc">
                 <div class="swiper-button-prev"></div>
                 <div v-for="(item,index) of bennrtList" class="flex ac jc" :key="index">
                     <div :class="['default-item',bannerIndex == index ? 'active-banner-item' : '']"></div>
                </div>
                 <div  class="swiper-button-next"></div>
            </div>
           
        </div>
        <div v-if="isContainer('/dynamic/info') || isContainer('/dynamic/notice')" class="news-infomation">
            <div class="tabs flex ac jc">
                <div v-if="isContainer('/dynamic/info')" :class="['tab-menu','fwb',articleIdx==0?'active':'']" @click="changeTab(0)">{{$t('home.information')}}<div class="bottom-line"></div></div>
                <div v-if="isContainer('/dynamic/notice')" :class="['tab-menu','fwb',articleIdx==1?'active':'']" @click="changeTab(1)">{{$t('home.notice')}} <div class="bottom-line"></div></div>
            </div>
            <div class="info-swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-item" v-if="isContainer('/dynamic/info')">
                        <div class="info-line "  >
                            <div class="flex fw jb">
                                 <div class="info-item flex" v-for="(item,index) of commonList" :key="index">
                                    <div class="item-date">
                                        <div class="item-date-year fwb">{{item.create_time_year_str}}</div>
                                        <div class="item-date-day double">{{item.create_time_month_str}}</div>
                                    </div>
                                    <div class="item-msg">
                                        <div @click="goPageDetail(item.content)" class="item-msg-title fwb">{{item.name}}</div>
                                        <div class="item-msg-desc"> {{item.synopsis}} </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div @click="goMore" class="more">
                            <span class="btn more-btn" style="margin: 0 auto">{{$t('home.more')}}</span>
                        </div>
                    </div>
                    <div class="swiper-slide swiper-item">
                        <div class="info-line "  v-if="isContainer('/dynamic/notice')">
                            <div class="flex fw jb">
                                 <div class="info-item flex" v-for="(item,index) of noticList" :key="index">
                                    <div class="item-date">
                                        <div class="item-date-year fwb">{{item.create_time_year_str}}</div>
                                        <div class="item-date-day double">{{item.create_time_month_str}}</div>
                                    </div>
                                    <div class="item-msg">
                                        <div @click="goPageDetail(item.content,item.domain_annex)" class="item-msg-title fwb">{{item.name}}</div>
                                        <div class="item-msg-desc"> {{item.synopsis}} </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div @click="goMore" class="more">
                            <span class="btn more-btn">{{$t('home.more')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module flex ac jc">
            <div v-if="isContainer('/surveys/gallery')" class="gallery">
                <img v-if="config.scenic_images_info &&  config.scenic_images_info.image.value[0]" :src="config.scenic_images_info.image.value[0]" alt="">
                <div class="fd">
                    <div class="text-btn flex jb">
                        <span class="label">{{$t('home.beautyGallery')}}</span>
                        <div @click="goPage('/surveys/gallery')" class="btn more">{{$t('home.appreciate')}}</div>
                    </div>
                </div>
            </div>
            <div v-if="config.home" class="guide">
                <div class="time-line flex ac">
                    <div  class="time-item">
                        <div class="time tc">{{config.home.open_time.value}}</div>
                        <div class="desc">{{$t('home.openingTime')}}</div>
                    </div>
                    <div class="line"></div>
                    <div class="time-item">
                        <div class="time tc">{{config.home.stop_buy_time.value}}</div>
                        <div class="desc">{{$t('home.stopTicketTime')}}</div>
                    </div>
                    <div class="line"></div>
                    <div class="time-item">
                        <div class="time tc">{{config.home.stop_enter_time.value}}</div>
                        <div class="desc">{{$t('home.stopTime')}}</div>
                    </div>
                </div>
                <div class="btn-group flex ac" v-if="config && config.home && config.home.jump_option">
                    <span v-for="( item,index ) of config.home.jump_option.value" v-if="isContainer(item.page)"  :key="index"
                      @click="goPage(item.page)" class="entrance fwb only">{{item.name}}</span>
       
                </div>
                <div class="other-msg flex">
                    <div class="code-info">
                        <img v-if="config.home.qrcode_image.value[0]" :src="config.home.qrcode_image.value[0]" alt="">
                        <div v-if="config && config.home.qrcode_name.value" class="msg">{{config.home.qrcode_name.value}}</div>
                    </div>
                    <div class="service-info">
                        <div class="label tel">{{$t('home.scenicSpotInformationTelephone')}}：</div>
                        <div class="text">{{config.home.scenic_tel.value}}</div>
                        <div class="label clock">{{$t('footer.time')}}：</div>
                        <div class="text">{{config.home.service_time.value}}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 精彩活动 -->
           <div v-if="isContainer('/dynamic/activity') && config && config.activity_info" class="active-box m-b-0" :style="{ backgroundImage : 'url(' + config.activity_info.image.value +')' }">
            <div class="active-content main-auto">
                <div class="header p-t-50">
                    <div class="title fwb m-t-0">{{$t('home.activity')}}</div>
                    <div v-if="config && config.activity_info && config.activity_info.content" class="introduce double" v-html="config.activity_info.content.value"></div>
                </div>
                 <div v-if="activityList.length > 1" id="certify">
                    <div class="swiper-container">
                        <div  class="swiper-wrapper">
                            <div  v-for="(item,index) of activityList" class="swiper-slide" :key="index">
                                <div class="activity-images" :style="{ backgroundImage : 'url(' +  item.domain_image +')' }"> </div>
                                <div class="tags">
                                            <span>{{item.label}}</span>
                                        </div>
                                <div class="title fwb only">{{item.name}}</div>
                                <div class="desc double">{{item.synopsis}}</div>
                            </div>
                    </div>   
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
                 <div v-if="activityList.length == 1" id="certify" class="flex ac jc">
                         <div  v-for="(item,index) of activityList" class="swiper-slide" :key="index">
                                <div class="activity-images" :style="{ backgroundImage : 'url(' +  item.domain_image +')' }"> </div>
                                <div class="tags">
                                            <span>{{item.label}}</span>
                                        </div>
                                <div class="title fwb only">{{item.name}}</div>
                                <div class="desc double">{{item.synopsis}}</div>
                            </div>
                  
                </div>
                <div class="more m-t-10">
                    <span @click="goPage('/dynamic/activity')" class="btn more-btn">{{$t('home.more')}}</span>
                </div>
            </div>
        </div>
      
      <!-- 720全景 -->
      <div v-if="config.vr_info && config.vr_info.image.value && isContainer('/surveys/720scenic')" class="all-scenic-box flex dc ac jc" :style="{ backgroundImage : 'url('+ config.vr_info.image.value[0] +')' }">
           <div class="main-box flex dc ac jc">
                  <div class="all-scenic-title-text">{{$t('home.720panorama')}}</div>
                    <div v-if="config.vr_info && config.vr_info.synopsis" class="all-scenic-detail-text double" > {{ config.vr_info.synopsis.value }} </div>
                    <div @click="goPage('/surveys/720scenic')" class="more">
                        <div class="btn more-btn m-t-20"> {{$t('home.exploreNow')}} </div>
                    </div>
           </div>
         
      </div>


        <!-- 图文介绍 -->
        <div v-if="isContainer('/surveys/scenic')" class="scenic-box">
            <div class="header">
                <div class="title fwb">{{$t('home.scenic')}}</div>
                <div v-if="config && config.sign_scenic_info && config.sign_scenic_info.content" class="introduce double" v-html="config.sign_scenic_info.content.value"></div>
            </div>
            <div class="scenic-info">
                <div class="item" v-for="(item,index) of scenicList" :key="index">
                    <img v-if="item.domain_image" :src="item.domain_image" alt>
                     <div class="title fwb m-l-0 m-b-0">{{item.name}}</div>
                     <div class="open-time">{{$t('home.openTime')}}：{{item.open_time}}</div>
                     <div class="desc double m-l-0 m-b-0">{{item.synopsis}}</div>
                     <div @click="goScenicDetail(item.content)" class="to-detail">
                         <span class="link">{{$t('home.viewDetails')}}</span>
                     </div>
                </div>
            </div>
            <div @click="goPage('/surveys/scenic')" class="more">
                <span class="btn more-btn">{{$t('home.more')}}</span>
            </div>
        </div>
        <footerTab></footerTab>
        <div v-if="showTopButton" @click="backTop" class="return-top flex dc ac jc">
            <div class="top-icon"></div>
            <div class="top-text tc">{{$t('home.top')}}</div>
        </div>
        <div v-if="isContainer('/aboutUs/messageBoard')"  @click="goPage('/aboutUs/messageBoard')" class="message-box flex dc ac jc">
            <div class="message-icon"></div>
            <div class="message-text tc">{{$t('home.message')}}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import {Indicator } from "mint-ui";
import Name from 'wowjs' 
import Swiper from "swiper"
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab,
    },
    data() {
        return {
            bannerImg:[],
            infoSwiper: null,
            articleIdx: 0,
            show: "",
            info:[],
            activity:[],
            route:[],
            news:[],
            choice_zixun:{},
            banner_info:"",
            LangeList : [],
            infoList : [],
            commonList : [],
            noticList : [],
            activityList : [],
            scenicList : [],
            showTopButton : false,
            bannerSwiper : "",
            bannerIndex : ""
        }
    },
    watch: {
        activityList() {
            const ts = this;
            ts.$nextTick(() => {
                ts.initSwiper();
            });
        },
        bennrtList() {
            const ts = this;
            ts.$nextTick(() => {
                ts.initSwiper();
            });
        },
    },
    computed: {
      ...mapGetters(['config','bennrtList','menuList']),
    },
    watch :  {
        menuList(val){
             this.show = val[0]?.id
        }
    },
    async mounted() {
        this.show = this.$store.getters.menuList[0]?.id
 
        Indicator.open(this.$t('tip.loading'));
            await this.commonGetList('os_scenic');
            await this.commonGetList('os_activity');
        // 获取官网菜单+对应banner列表
        await  this.getMenuList();
        let containerInfo =  this.isContainer('/dynamic/info');
        let containerNotice =  this.isContainer('/dynamic/notice');
        console.log("containerInfo",containerInfo);
        console.log("containerNotice",containerNotice);
          if ( !containerInfo &&  containerNotice ) {
                this.articleIdx = 1;
          }
        // 新闻资讯列表
        await this.commonGetList('os_information');
          await this.commonGetList('os_notice');
        this.initSwiper();
         Indicator.close();
          window.addEventListener('scroll', this.scrollToTop)
          this.showActivity = this.isContainer('/dynamic/activity')
       
    },
    destroyed () {
        window.removeEventListener('scroll', this.scrollToTop)
    },
    methods: {
        // 当前模块是否有权限
        isContainer(page){           
            let result = false;
               this.$store.getters.menuList.forEach( (item,index) => {
                  
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (   childrenItem.page == page ) {
                                result = true;
                            }
                    })  
                }
            } )
            return result;
        },
        getRoute(e) {
            // 如果是外链
            if ( e.jump_type == 2 ) {
                window.open(e.url,'_blank');
                return;
            }
            // 如果是内部跳转
             if ( e.jump_type == 1 ) {
                   let resultItem = "";
                    let firstMenu = "";
                    let secondMenu = "";
                    this.$store.getters.menuList.forEach( (item,index) => {
                        if ( item.children.length > 0 ) {
                            item.children.forEach( ( childrenItem,childrenIndex ) => {
                                    if (   childrenItem.id == e.menu_id ) {
                                        resultItem = childrenItem;
                                        firstMenu = item.name;
                                        secondMenu = childrenItem.name;
                                    }
                            })  
                        }
                    } )
                    const nowLocation = firstMenu + " > " +  secondMenu
                    this.$store.dispatch('setNowLocation',nowLocation)
                    this.$store.dispatch('setSecondMenu',secondMenu)
                    console.log("resultItem",resultItem);
                    util.openPage({url : resultItem.page,data : { id : resultItem.id,parent_id :resultItem.parent_id }})
            }
        },
        // 点击图片回到顶部方法，加计时器是为了过渡顺滑
        backTop () {
            const that = this
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                clearInterval(timer)
                }
            }, 16)
        },
 
        // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
        scrollToTop () {
            const that = this
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            that.scrollTop = scrollTop
            if (that.scrollTop > 60) {
            that.showTopButton = true
            } else {
            that.showTopButton = false
            }
        },
        goPageDetail(htmlData,domainAnnex){
              // 0 跳新闻资源 1景区公告
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach( (item,index) => {
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (  (this.articleIdx == 0 && childrenItem.page == "/dynamic/info") ||  (this.articleIdx == 1 && childrenItem.page == "/dynamic/notice") ) {
                                e = childrenItem;
                                firstMenu = item.name;
                                secondMenu = childrenItem.name;
                            }
                    })  
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            console.log(domainAnnex)
            let domain_annex=''
            if(domainAnnex!='' && domainAnnex!=undefined && domainAnnex.length!=0){
                domain_annex=JSON.stringify(domainAnnex)
            }
            util.openPage({url : e.page,data : { id : e.id,parent_id :e.parent_id,htmlData,annex:domain_annex }})
        },
        goMore(){
            // 0 跳新闻资源 1景区公告
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach( (item,index) => {
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (  (this.articleIdx == 0 && childrenItem.page == "/dynamic/info") ||  (this.articleIdx == 1 && childrenItem.page == "/dynamic/notice") ) {
                                e = childrenItem;
                                firstMenu = item.name;
                                secondMenu = childrenItem.name;
                            }
                    })  
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url : e.page,data : { id : e.id,parent_id :e.parent_id }})
        },
        goPage(page){
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach( (item,index) => {
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (   childrenItem.page == page ) {
                                e = childrenItem;
                                firstMenu = item.name;
                                secondMenu = childrenItem.name;
                            }
                    })  
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            if ( e.jump_type == 2 ) {
                window.open(e.url,'_blank');
                return;
            } else {
                  util.openPage({url : e.page,data : { id : e.id,parent_id :e.parent_id }})
            }
          
        },
        goScenicDetail(content){
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach( (item,index) => {
                if ( item.children.length > 0 ) {
                    item.children.forEach( ( childrenItem,childrenIndex ) => {
                            if (   childrenItem.page == "/surveys/scenic" ) {
                                e = childrenItem;
                                firstMenu = item.name;
                                secondMenu = childrenItem.name;
                            }
                    })  
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url : e.page,data : { id : e.id,parent_id :e.parent_id,content }})
        },
          commonGetList(type) {
              const ts = this;
            return new Promise( (resolve) => {
                  util.requests("post", {
                    url: "os/commonGetList",
                    data : { table : type }
                    }).then((res) => {
                        if (res.code) {
                            if ( ts.articleIdx == 0 && type == 'os_information' ) {
                                 ts.infoList = res.data.list.filter( (item,index) => index < 4 )
                            } 
                              if ( ts.articleIdx == 1 && type == 'os_notice' ) {
                                 ts.infoList = res.data.list.filter( (item,index) => index < 4 )
                            } 
                            if ( type == 'os_information' ) {
                                   ts.commonList  = res.data.list.filter( (item,index) =>  index < 4 )
                            }
                            if ( type == 'os_notice' ) {
                                   ts.noticList  = res.data.list.filter( (item,index) => index < 4 )
                            }
                            if ( type == 'os_activity' ) {
                                   ts.activityList  = res.data.list.filter( (item,index) => index < 8 )
                            }
                               if ( type == 'os_scenic' ) {
                                   ts.scenicList  = res.data.list.filter( (item,index) => index < 8 )
                                  
                            }

                            
                            
                         
                        }
                        resolve()
                }).catch( () => {
                    resolve()
                } );
            } )
        },
          getMenuList() {
              const ts = this;
            return new Promise( (resolve) => {
                  util.requests("post", {
                    url: "os/getMenuList",
                    }).then((res) => {
                    
                        if (res.code) {
                            ts.bannerImg = res.data.list[0].bennrt_list
                        }
                        resolve()
                }).catch( () => {
                    resolve()
                } );
            } )
        },
        initSwiper(){
            const ts =this;
            this.infoSwiper = new Swiper(".info-swiper", {
                autoplay: false,
                 centeredSlides: true,

            });
            if ( this.bennrtList.length > 1 ) {
                    this.bannerSwiper =   new Swiper(".banner-swiper", {
                        autoplay: true,
                        loop: true,
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                            on: {
                                slideChange : function(){
                                    
                                        ts.bannerIndex = this.realIndex
                                        ts.$forceUpdate()
                                }
                            }
                        });
            }
        
             new Swiper('#certify .swiper-container', {
                watchSlidesProgress: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                loopedSlides: 5,
                autoplay: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    //clickable :true,
                },
                on: {
                    progress: function(progress) {
                        for (let i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i);
                            var slideProgress = this.slides[i].progress;
                            let modify = 1;
                            if (Math.abs(slideProgress) > 1) {
                                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                            }
                            let translate = slideProgress * modify * 210 + 'px';
                            let scale = 1 - Math.abs(slideProgress) / 5;
                            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                            slide.css('zIndex', zIndex);
                            slide.css('opacity', 1);
                            if (Math.abs(slideProgress) > 3) {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    setTransition: function(transition) {
                        for (var i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i)
                            slide.transition(transition);
                        }

                    }
                }

            })
        },
        changeTab(idx){
            this.articleIdx = idx;
            this.infoSwiper.slideTo(idx)
            // if ( idx == 0 ) {
            //     this.infoList = this.commonList
            // }
            // if ( idx == 1 ) {
            //     this.infoList = this.noticList
            // }
        },
    
        post() {
            const ts = this;
            Indicator.open(this.$t('tip.loading'));
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.bannerImg = res.data[0].banner_list;
                        ts.banner_info = ts.bannerImg[0]
                        ts.info =  res.data[0].children;
                    }
            });
            util.requests("post", {
                    url: "pc/homeData",
                    data:{type:2}
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.activity = res.data.activity;
                        ts.route = res.data.route;
                        ts.news = res.data.travel;
                        ts.choice_zixun = ts.news[0]
                    }
            });
        },
        changeInfo(e) {
            this.choice_zixun = this.news[e]
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.activity.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.activity.length - 1] = ts.activity[0]
                ts.activity = list
            } else {
                var list = [];
                list.push(ts.activity[ts.activity.length - 1])
           
                ts.activity.forEach((item,index) => {
                    if(index < ts.activity.length -1) {
                        list.push(item)
                    }
                });
                ts.activity = list
            }
        },
        goto() {
            this.$router.push({path:'/scenic',query: {type:'activity'}})
        },
        goDetail(e,f) {
            if (f == '1') {
                this.$router.push({path:'/detail',query: {type: 'activity', id:e.id}})
            } else if (f == '2') {
                this.$router.push({path:'/detail',query: {type: 'route', id:e.id}})
            } else if (f == '3') {
                this.$router.push({path:'/detail',query: {type: 'travel', id:e.id}})
            }
        },
        goDetails() {
            this.$router.push({path:'/line'})
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>
<style scoped lang="scss">
 .introduce >>> img {
        max-width: 1000px !important;
    }
    .images {
        width: 100%;
        height: 830px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
.banner {
        .swiper-button-prev,.swiper-button-next{
            position: initial !important;
            width: 40px;
            height: 40px;
            display: block;
            // top: calc(50% + 48px);
            &::after{
                display: none;
            }
        }
        .swiper-button-prev{
            background: url("../assets/banner-left.png") no-repeat;
            background-size: cover;
            left: 60px;
            opacity: 1 !important;
        }
        .swiper-button-next{
            background: url("../assets/banner-right.png") no-repeat;
            background-size: cover;
            right: 60px;
              opacity: 1 !important;
        }
    }
    // 精彩活动轮播图
    
#certify {
	position: relative;
	width: 1500px;
	margin: 0 auto ;
    margin-top : 50px !important
}

#certify .swiper-container {
	padding-bottom:20px;
}
.m-t-10 {
    margin-top: 10px !important;
}

#certify  .swiper-slide {
	width: 520px;
	height: 468px;
	background: #fff;
	box-shadow: 0 8px 30px #ddd;
}
#certify  .swiper-slide img{
    width: 100%;
	display:block;
}
#certify  .swiper-slide p {
	line-height: 98px;
	padding-top: 0;
	text-align: center;
	color: #636363;
	font-size: 16px;
	margin: 0;
}

#certify .swiper-pagination {
	width: 100%;
	bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px;
	border: 3px solid #fff;
	background-color: #d5d5d5;
	width: 10px;
	height: 10px;
	opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
	border: 3px solid #00aadc;
	background-color: #fff;
}

#certify .swiper-button-prev {
	left: -30px;
	width: 45px;
	height: 45px;
    top: 230px;
	background: url("../assets/wm_button_icon.png") no-repeat;
	background-position: 0 0;
	background-size: 100%;
}

#certify .swiper-button-prev:hover {
	background-position: 0 -46px;
	background-size: 100%
}

#certify .swiper-button-next {
    top: 230px;
	right: -30px;
	width: 45px;
	height: 45px;
	background: url("../assets/wm_button_icon.png") no-repeat;
	background-position: 0 -93px;
	background-size: 100%;
}

#certify .swiper-button-next:hover {
	background-position: 0 -139px;
	background-size: 100%
}
.swiper-button-next:after,.swiper-button-prev:after {
    content: none !important;
}
.activity-images {
    width: 100%;
    height: 317px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
     .tags{
        margin: 0px 16px 0px;
        line-height: 0px;
        span{
            font-size: 10px;
            color: var(--bg-color);
            border: 1px solid var(--bg-color);
            padding: 3px 8px;
            border-radius: 15px;
        }
    }
    .title{
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 25px 16px 15px;
    }
    .desc{
        font-size: 14px;
        color: #333333;
        /*margin: 0 16px ;*/
    }
    .return-top {
        width: 108px;
        height: 108px;
        position: fixed;
        bottom: 100px;
        right: 0px;
        background-color: var(--bg-color);
        border-radius: 4px;
        z-index: 99;
        :hover {
            //   color: var(--bg-color) !important;
        }
        .top-icon {
            width: 42px;
            height: 42px;
            background-size: 100%  100%;
            background-image: url("../assets/deafult-top.png");
            cursor: pointer;

        }
        // .top-icon:hover {
        //     background-image: url("../assets/active-top.png") !important;
            
        // }
        .top-text {
            cursor: pointer;
            margin-top: 10px;
            font-size: 16px;
            color: #fff;
        }
    }
    .message-box {
        width: 108px;
        height: 108px;
        position: fixed;
        bottom: 215px;
        right: 0px;
        background-color: var(--bg-color);
        border-radius: 4px;
        z-index: 99;
        :hover {
            //   color: var(--bg-color) !important;
        }
        .message-icon {
            width: 42px;
            height: 42px;
            background-size: 100%  100%;
            background-image: url("../assets/deafult-home-message.png");
            cursor: pointer;

        }
        // .message-icon:hover {
        //     background-image: url("../assets/active-home-message.png") !important;
            
        // }
        .message-text {
            cursor: pointer;
            margin-top: 10px;
            font-size: 16px;
            color: #fff;
        }
    }
    .all-scenic-box {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 1;
        .main-box {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
             .all-scenic-title-text {
                font-size: 32px;
                color: #fff;
                font-weight: bold;
            }
            .all-scenic-detail-text {
                margin: 30px 0;
                font-size: 20px;
                color: #fff;
                max-width: 700px;
            }
        }
       
    }
    .m-t-20 {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    .m-t-0 {
        margin-top: 0 !important;
    }

    .p-t-50 {
        padding-top: 50px !important;
    }
    .activity-only-images {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .m-l-0 {
        margin-left: 0px !important;
    }

    .tc {
        text-align: center !important;
    }

    .m-b-0 {
        margin-bottom: 0px !important;
    }

    .zdy {
        position: absolute;
        right: 60px;
        
        bottom: 30px;
        z-index: 99;
    }

    .default-item {
        margin: 0 5px;
        position: relative;
        bottom: 7.5px;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        background-image: url("../assets/default-banner-icon.png");
    }

    .active-banner-item {
         background-image: url("../assets/active-banner-icon.png") !important;
    }

    .scenic-box {
        margin-top: 60px !important;
    }
    
</style>