<template>
  <section class="header-tab flex ac jb">
    <div class="left flex ac jc">
      <img v-if="logo" :src="logo" alt="">
      <span class="only" v-if="name">{{ name}}</span>
    </div>
    <div class="main flex ac">
      <section :key="index" v-for="(item,index) in menuList" @click="getRoute(item,item.page)" :class="['tabs flex ac jc', $parent.show == item.id ? 'active': '']">{{item.name}}
        <div :class="['bottom-radius flex ac jc',$parent.show == item.id ? 'active': '']"></div>
        <section v-if="item.children.length > 0" class="header-pop">

          <section :key="childIndex" :class="['flex ac jc pop-name',id==childItem.id ? 'active':'']" @click.stop="getModel(childItem,childItem.page,item.name,childItem.name,item.bennrt_list)" v-for="(childItem,childIndex) in item.children">
            <div class="only"> {{childItem.name}} </div>
          </section>
        </section>
      </section>
    </div>
    <div class="right flex ac">
      <select v-if="langArr.length > 0" class="textarea flex ac jc w50" v-model="lang" @change="changeLang()">
        <option :key="index" v-for="(item,index) in langArr" :value="item.val">{{item.name}}</option>
      </select>
    </div>
  </section>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from 'vuex';
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { getLanguage, setLanguage } from "@/utils/auth";
import { Indicator } from "mint-ui";
export default {
  name: "home",
  components: {
    HeaderTab,
    footerTab
  },
  computed: {
    ...mapGetters(['menuList', 'logo', 'name', 'langArr']),
  },
  props: {
    getPoint: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      lang: "",
      id: '',
      info: ""
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }

    Indicator.open(this.$t('tip.loading'));
    // 获取语言列表
    await this.getLangConfig()
    this.lang = getLanguage()

    // 获取官网菜单+对应banner列表
    await this.getMenuList();
    if (!this.$store.getters.config) {
      await this.getSettingList();
    }

    Indicator.close();
  },
  methods: {
    getLangConfig() {
      const ts = this;
      return new Promise((resolve) => {
        util.requests("post", {
          url: "os/getLangConfig",
        }).then((res) => {
          if (res.code && res.data.length > 0) {

            const langArr = res.data.map((item, index) => {
              const newItem = { ...item };
              newItem.val = item.code
              return newItem
            })
            if (langArr.length > 0) {

              ts.$store.dispatch('setLangArr', langArr)
              console.log("!getLanguage()", !getLanguage());
              if (!getLanguage()) {
                ts.lang = langArr[0].val;
                setLanguage(ts.lang)
                console.log("set--ts.lang", ts.lang);
              }

            }


          }
          resolve()
        }).catch(() => {
          resolve()
        });
      })
    },
    getMenuList() {
      const ts = this;
      return new Promise((resolve) => {
        util.requests("post", {
          url: "os/getMenuList",
        }).then((res) => {
          if (res.code) {
            ts.$store.dispatch('setMenuList', res.data.list)
            // if ( ts.$route.query.parent_id ) {
            let tabList = [];
            let secondMenu = "";
            let firstMenu = "";
            let bennrtList = []
            res.data.list.forEach((item, index) => {
              if (item.id == ts.$route.query.parent_id) {
                tabList = item.children
                firstMenu = item.name;
                bennrtList = item.bennrt_list;
              }
              if (!ts.$route.query.parent_id && index == 0) {
                bennrtList = item.bennrt_list;
              }
            })
            if (tabList.length > 0) {
              tabList.forEach((item, index) => {
                if (item.id == ts.$route.query.id) {
                  secondMenu = item.name
                }
              })
            }
            const nowLocation = firstMenu + " > " + secondMenu
            ts.$store.dispatch('setNowLocation', nowLocation)
            ts.$store.dispatch('setTabList', tabList)
            ts.$store.dispatch('setSecondMenu', secondMenu)
            ts.$store.dispatch('setBennrtList', bennrtList)
            // }
          }
          resolve()
        }).catch(() => {
          resolve()
        });
      })
    },

    getSettingList() {
      const ts = this;
      return new Promise((resolve) => {
        util.requests("post", {
          url: "os/getSettingList",
        }).then((res) => {

          if (res.code) {
            ts.info = res.data
            if (res.data.system_info.logo.value.length > 0) {
              ts.$store.dispatch('setLogo', res.data.system_info.logo.value[0])
            }
            if (res.data.system_info.scenic_name.value) {
              ts.$store.dispatch('setName', res.data.system_info.scenic_name.value)
              document.title = res.data.system_info.scenic_name.value;
            }
            if (res.data.style.color) {
              ts.$store.dispatch('setThemeColor', res.data.style)
            }
            ts.$store.dispatch('setConfig', res.data)

            // 动态设置关键词 描述
            let head = document.getElementsByTagName('head');
            let meta = document.createElement('meta');
            if (res.data.system_info.s_e_page_description) {
              document.querySelector('meta[name="description"]').setAttribute('content', res.data.system_info.s_e_page_description.value)
              head[0].appendChild(meta)

            }
            if (res.data.system_info.s_e_website_keywords) {
              document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.system_info.s_e_website_keywords.value)
              head[0].appendChild(meta)
            }
            if (res.data.system_info.baidu_html_verify_code) {
              var pat = /content=[\"|'](.*?)[\"|']/gi;
              var r = /["|'](.*)["|']/;
              document.querySelector('meta[name="baidu-site-verification"]').setAttribute('content', res.data.system_info.baidu_html_verify_code.value.match(pat)[0].match(r)[1])
              head[0].appendChild(meta)
            }
          }
          resolve()
        }).catch(() => {
          resolve()
        });
      })
    },

    changeLang() {
      const ts = this;
      return new Promise((resolve) => {
        util.requests("post", {
          url: "os/getLangConfig",
        }).then((res) => {
          if (res.code && res.data.length > 0) {
            const isContainer = false;
            const langArr = res.data.map((item, index) => {
              // 如果包含这个语种才更新语种
              if (item.code == ts.lang) {
                setLanguage(ts.lang)
              }
            })
          }
          if (ts.$route.path == '/') {
            window.location.reload();
          } else {
            util.openPage({ url: "/" })
            window.location.reload();
          }
          resolve()
        }).catch(() => {
          resolve()
        });
      })

    },
    getRoute(e, url) {
      // 如果是外链
      if (e.jump_type == 2) {
        if (e.url) {
          window.open(e.url, '_blank');
        }
        return;
      }
      // 一级菜单并且是首页就跳转
      if (!(e.children && e.children.length > 0) && url == "/") {
        util.openPage({ url })
      }

    },
    getModel(e, url, firstMenu, secondMenu, bennrt_list) {
      // 如果是外链
      if (e.jump_type == 2) {
        window.open(e.url, '_blank');
        return;
      }
      const nowLocation = firstMenu + " > " + secondMenu
      this.$store.dispatch('setNowLocation', nowLocation)
      this.$store.dispatch('setSecondMenu', secondMenu)
      util.openPage({ url, data: { id: e.id, parent_id: e.parent_id } })
      this.$store.dispatch('setBennrtList', bennrt_list)

    }
  }
}
</script>