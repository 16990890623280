<template>
    <div class="container">
         <div v-if="config.business_hours_info && config.business_hours_info.content" v-html="config.business_hours_info.content.value"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
var util = require("@/utils/util");
export default {
    data() {
        return {
            info : {
                home : {
                    open_time : {
                        value : ""
                    },
                    stop_buy_time : {
                        value : ""
                    },
                    stop_enter_time : {
                        value : ""
                    }
                }
            }
        }
    },
    computed: {
      ...mapGetters(['config']),
    },
    async  mounted() {
    },
    methods : {
    }
}
</script>
<style lang="scss" scoped>
    .container {
        margin-top: 61px;
        margin-left: 45px;
        font-size: 18px;
        .title {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            margin-bottom: 34px;
        }
        .label {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-right: 50px;
        }
        .label-value {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
        }
    }
    .m-b-24 {
        margin-bottom: 24px;
    }
</style>