<template>
  <div id="app" :style="themeColor">
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return{
            rem:'',
        }  
    },
    computed: {
        styleObject(){
            return this.setThemeColor()
        },
        ...mapGetters(['themeColor']),
    },
    mounted() {
                // 改变窗口大小时重新设置 rem
        if (document.body.offsetWidth <750) {
            // window.location.href = 'https://m.guchengwan.com' + window.location.hash
        }
        this.setRem();
        this.setThemeColor();
        // 改变窗口大小时重新设置 rem
        window.onresize = () => {
            this.setRem();
            if (document.body.offsetWidth < 750) {
                // window.location.href = 'https://m.guchengwan.com' + window.location.hash
            }
        };
    },
    methods: {
        setRem() {
            const baseSize = 100;
            const baseScale = baseSize / 1920; // 1920的设计图
            let widthScale = window.innerWidth; // 当前窗口的宽度
            const heightScale = window.innerHeight; // 当前窗口的高度
            // 尺寸换算
            const comparedHeight = (widthScale * 1080) / 1920;
            if (heightScale < comparedHeight) {
                widthScale = (heightScale * 1920) / 1080;
            }
            // 计算实际的rem值,得到该宽度下的相应font-size值,并赋予给html的font-size,
             const rem = widthScale * baseScale + 'px';
            document.documentElement.style.fontSize = rem;
        },
        setThemeColor() {
            return {
                "--bg-color": '#64A346',
                "--bg-color-o": '#64A3461a',
            }
        }
    }
}
</script>

