<!-- 顶部banner -->
<template>
    <div class="detail-info">
        <div class="title">{{ title }}</div>
         <div v-html="htmlData"></div>
         <img v-if="qrcode_url" class="qr_img" width="160" height="160" :src="qrcode_url" alt="扫码进入小程序">
    </div>
</template>
<script>
var util = require("@/utils/util");
export default {
    name: "detail-info",
    props: {
        htmlData : String,
        title :String,
        qrcode_url: String,
    },
    computed: {
    },
    data() {
        return {
        }
    },
}
</script>
<style scoped>
    .detail-info >>> img {
        max-width: 1000px !important;
    }
    .title {
        font-size: 30px;
        font-weight: bold;
        
    }
    .qr_img {
        transform: translateX(-50%);
        position: relative;
        left: 50%;
        margin-top: 50px;
    }
</style>