<template>
    <div class="container">
        <div class="list-item-box " v-for="(item,index) of list" :key="index">
            <div class="flex m-b-17">
                    <div  class="a-icons flex ac jc">
                        A
                    </div>
                    <div class="list-item ">
                        <div class="list-item-title">{{item.name}}</div>
                    </div>
            </div>

            <div class="flex ">
                    <div  class="q-icons flex ac jc">
                        Q
                    </div>
                    <div class="list-item ">
                        <div class="list-item-answer">{{item.answer}}</div>
                    </div>
            </div>
    
            <div class="line-icon"></div>
        </div>
          <!-- ============分页=============== -->
        <pagination-box :pagination="pagination" @pageChange="pageChange" ></pagination-box>
    </div>
</template>
<script>
import { Indicator } from "mint-ui";
var util = require("@/utils/util");
import paginationBox from '@/components/common/pagination.vue'; 
export default {
    data(){
        return {
            list : [],
            pagination: {}, //分页数据
            page:1
        }
    },
    components: {
		paginationBox,
	},
    async  mounted() {
        Indicator.open(this.$t('tip.loading'));
        await this.commonGetList('os_faq');
        Indicator.close()
    },
    methods : {
        commonGetList(table){
            const ts = this;
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : 5 , page : ts.page}
                }).then( (res) => {
                    if ( res.code ) {
                        ts.list = res.data.list
                         ts.pagination = {
                            page: res.data.current_page,
                            pagecount: res.data.last_page,
                            total: res.data.total,
                            pagesize: res.data.per_page
                        }
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
                          // 分页操作
         pageChange: async function(page) {
            this.page = page
              Indicator.open(this.$t('tip.loading'));
                await this.commonGetList('os_faq');
                Indicator.close()
            },
    }
    
}
</script>
<style lang="scss" scoped>
    .container {
        margin-top: 52px;
        .list-item-box {
            border: 1px solid #E3E3E3;
            padding: 15px;
            margin-bottom: 24px;
            position: relative;
            .a-icons {
             width: 32px;
             height: 32px;
             background: var(--bg-color-o);
             border-radius: 4px 4px 4px 4px;
             margin-right: 16px;
             color: var(--bg-color);
             font-size: 23px;
             font-weight: bold;
             margin-top: 5px;   
             margin-left: 20px;
            }
            .q-icons {
               width: 32px;
                height: 32px;
                background: var(--bg-color-o);
                border-radius: 4px 4px 4px 4px;
                margin-right: 16px;
                color: var(--bg-color);
                font-size: 23px;
                font-weight: bold;
                margin-top: 5px;   
                     margin-left: 20px;
            }
            .list-item {
                flex: 1;
                .list-item-title {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 8px;
                }
                .list-item-answer {
                 font-size: 14px;
                 font-family: PingFang SC-Regular, PingFang SC;
                 font-weight: 400;
                 color: #333333;
                }
            }
            .line-icon {
                position: absolute;
                top: 36px;
                left: 0;
                width: 4px;
                height: 48px;
                background-color: var(--bg-color);
            }
        }
    }
    .m-b-17 {
        margin-bottom: 17px;
    }
</style>