<template>
	<div class="pagination-box">
		<el-pagination  layout="total, prev, pager, next, jumper" :total="parseInt(pagination.total)" :page-size="parseInt(pagination.pagesize)" :current-page="parseInt(pagination.page)" :page-count="parseInt(pagination.pagecount)" @current-change="pageChange" ></el-pagination>
	</div>  
</template>

<script>
	export default {
		name: "pagination-box",
		props: ['pagination'],
		data() {
			return {
				
			}
		},
		methods: {
      //页面点击跳转
      pageChange(page){
        window.scrollTo(0, 0);//跳转时页面置顶
        //val为跳转的页数
        this.$emit('pageChange',page)
      }
		}
	};
</script>
<style >
	
.pagination-box{
  text-align: right;
  margin-top: 15px
}

</style>
