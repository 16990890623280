<!-- 顶部banner -->
<template>
        <div v-if="bennrtList && bennrtList.length > 0" class="banner swiper-box">
            <div class="swiper-container banner-swiper">
                <div class="swiper-wrapper" >
                    <div @click="getRoute(item)" class="swiper-slide" v-for="item in bennrtList" :key="item.id">
                        <div :style="{ backgroundImage : 'url('+ item.domain_image + ')' }" class="images"></div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div v-if="bennrtList.length > 1" class="zdy flex ac jc">
                 <div class="swiper-button-prev"></div>
                 <div v-for="(item,index) of bennrtList" class="flex ac jc" :key="index">
                     <div :class="['default-item',bannerIndex == index ? 'active-banner-item' : '']"></div>
                </div>
                 <div  class="swiper-button-next"></div>
            </div>
        </div>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import Swiper from "swiper"
export default {
    name: "top-banner",
    props: {
        banner: {
            type: String,
            default: 'https://guchengwan.oss-cn-beijing.aliyuncs.com/image/6mrst7b6af00000.jpg'
        },
        height: {
            type: Number,
            default: 378
        }
    },
    watch: {
        bennrtList() {
            const ts = this;
            ts.$nextTick(() => {
                ts.initSwiper();
            });
        },
    },
    computed: {
        ...mapGetters(['bennrtList'])
    },
    data() {
        return {
            bannerIndex : ""
        }
    },
    mounted() {
        this.initSwiper()
    },
    methods : {
        getRoute(e) {
            // 如果是外链
            if ( e.jump_type == 2 ) {
                window.open(e.url,'_blank');
                return;
            }
            // 如果是内部跳转
             if ( e.jump_type == 1 ) {
                   let resultItem = "";
                    let firstMenu = "";
                    let secondMenu = "";
                    this.$store.getters.menuList.forEach( (item,index) => {
                        if ( item.children.length > 0 ) {
                            item.children.forEach( ( childrenItem,childrenIndex ) => {
                                    if (   childrenItem.id == e.menu_id ) {
                                        resultItem = childrenItem;
                                        firstMenu = item.name;
                                        secondMenu = childrenItem.name;
                                    }
                            })  
                        }
                    } )
                    const nowLocation = firstMenu + " > " +  secondMenu
                    this.$store.dispatch('setNowLocation',nowLocation)
                    this.$store.dispatch('setSecondMenu',secondMenu)
                    console.log("resultItem",resultItem);
                    util.openPage({url : resultItem.page,data : { id : resultItem.id,parent_id :resultItem.parent_id }})
            }
        },
           initSwiper(){
               const ts =this;
               if ( this.bennrtList.length > 1 ) {
                       new Swiper(".banner-swiper", {
                    autoplay: true,
                    loop: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        on: {
                            slideChange : function(){
                                    ts.bannerIndex = this.realIndex
                                    ts.$forceUpdate()
                            }
                        }
                    });
               }
        
        },
    }
}
</script>
<style scoped lang="scss">
    .banner {
           .images {
        width: 100%;
        height: 380px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
        .swiper-button-prev,.swiper-button-next{
            position: initial !important;
            width: 40px;
            height: 40px;
            display: block;
            // top: calc(50% + 48px);
            &::after{
                display: none;
            }
        }
        .swiper-button-prev{
            background: url("../assets/banner-left.png") no-repeat;
            background-size: cover;
            left: 60px;
            opacity: 1 !important;
        }
        .swiper-button-next{
            background: url("../assets/banner-right.png") no-repeat;
            background-size: cover;
            right: 60px;
              opacity: 1 !important;
        }
    }
    

    .zdy {
        position: absolute;
        right: 60px;
        
        bottom: 30px;
        z-index: 99;
    }

    .default-item {
        margin: 0 5px;
        position: relative;
        bottom: 7.5px;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
        background-image: url("../assets/default-banner-icon.png");
    }

    .active-banner-item {
         background-image: url("../assets/active-banner-icon.png") !important;
    }
</style>
